import type { Dataset, LocationQueryParams } from '../contract'
import type { DeepPartial } from '../utils/type-utils'
import { defaultDatasetConfiguration as commonDefaultDatasetConfig } from '@wix/wix-data-client-common'
import { readWriteTypeMap } from '../inverted-dependencies/Platform'
import { cleanObject } from '../utils'

export const defaultDatasetConfig = {
  id: '',
  parentId: null,
  type: 'regular' as const,
  collectionId: commonDefaultDatasetConfig.collectionName,
  readWriteType: readWriteTypeMap[commonDefaultDatasetConfig.readWriteType],
  deferred: commonDefaultDatasetConfig.deferred,
  pagination: {
    pageSize: commonDefaultDatasetConfig.pageSize,
    currentPage: 1,
    type: 'offset' as const,
  },
  rendering: {
    nestedFieldIds: [],
    connections: [],
    componentIdsToRender: [],
  },
  dataLoading: {
    dataIsInvalidated: false,
    referenceFieldIdsToFetch: [],
    filter: commonDefaultDatasetConfig.filter,
    sort: commonDefaultDatasetConfig.sort,
  },
  routerConfig: null,
}

const make = ({ config, data = null }: DeepPartial<Dataset> = {}): Dataset => ({
  config: {
    ...defaultDatasetConfig,
    ...cleanObject(config),
    pagination: {
      ...defaultDatasetConfig.pagination,
      ...cleanObject(config?.pagination),
    },
    rendering: {
      ...defaultDatasetConfig.rendering,
      ...cleanObject(config?.rendering),
    },
    dataLoading: {
      ...defaultDatasetConfig.dataLoading,
      ...cleanObject(config?.dataLoading),
    },
  },
  data,
})

const makeVirtual = (
  dataset: Dataset,
  {
    scopeId,
    itemId,
    scopedToItem = false,
  }: { scopeId: string; itemId: string; scopedToItem?: boolean },
) => ({
  ...dataset,
  config: {
    ...dataset.config,
    id: `${dataset.config.id}_componentId_${scopeId}_itemId_${itemId}`,
    parentId: dataset.config.id,
    pagination: {
      ...dataset.config.pagination,
      currentPage: 1,
      pageSize: scopedToItem ? 1 : dataset.config.pagination.pageSize,
    },
    dataLoading: {
      ...dataset.config.dataLoading,
      filter: scopedToItem
        ? { _id: { $eq: itemId } }
        : dataset.config.dataLoading.filter,
    },
  },
})

const getId = ({ config: { id } }: Dataset) => id

const getCurrentPage = ({
  config: {
    pagination: { currentPage },
  },
}: Dataset) => currentPage // current page numeration starts from 1

const getPageSize = ({
  config: {
    pagination: { pageSize },
  },
}: Dataset) => pageSize

const getOffset = (dataset: Dataset) => {
  return (getCurrentPage(dataset) - 1) * getPageSize(dataset)
}

const getFieldIdsToFetch = (dataset: Dataset) => {
  return dataset.config.dataLoading.fieldIdsToFetch
}

const initCurrentPage = (
  dataset: Dataset,
  queryParams: LocationQueryParams,
) => ({
  ...dataset,
  config: {
    ...dataset.config,
    pagination: {
      ...dataset.config.pagination,
      currentPage: Number(
        queryParams[
          dataset.config.type === 'router'
            ? 'page'
            : `${dataset.config.id}_page`
        ] ?? 1,
      ),
    },
  },
})

export default {
  make,
  makeVirtual,
  getId,
  getCurrentPage,
  getPageSize,
  getOffset,
  getFieldIdsToFetch,
  initCurrentPage,
}
